body {
   margin: 0;
  font-family: 'PT Sans Caption', sans-serif;
  background: url('background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

/* button {
  border: none; 
} */
.table-bordered {
  border-color: #000 !important;
}

.table-bordered td {
  border-color: #000 !important;
}

.table-bordered th {
border-color: #000 !important;
background-color: #C8C8C8 !important;
}

.jumbotron {
 background: rgba(255,255,255, 0.8);
}

.control-label{
 text-align: left;
}

.centered {
 position: fixed; top: 50%;
 left: 50%;
 margin-top: -50px;
 margin-left: -100px;
}

.loader {
 border: 16px solid #f3f3f3;
 border-radius: 50%; border-top: 16px solid #3498db;
 width: 70px;
 height: 70px;
 -webkit-animation: spin 2s linear infinite; /* Safari */
 animation: spin 2s linear infinite; 
}

/* Safari */
@-webkit-keyframes spin {
 0% { -webkit-transform: rotate(0deg); }
 100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }  
}